<template>
  <el-dialog
    id="SKUDictionaryDialog"
    ref="dialogWrapper"
    :title="title"
    :visible.sync="dialogShow"
    modal
    center
    :close-on-click-modal="false"
    width="90%"
    top="5vh"
    close-on-press-escape
    class="my-dialog abow_dialog"
    append-to-body
    @open="onOpen"
    @opened="onOpened"
    @close="onClose"
    @closed="onClosed"
    @keydown.enter.native="onFormEnter"
  >
    <el-header class="header2" height="48"
      ><el-row type="flex" justify="start" align="center">
        <TIButton icon="plus_green" @onClick="onAdd" />
        <TIButton icon="delete_red" @onClick="onDel" :disabled="!selectedRows2" />
        <TSeparator />
        <el-dropdown trigger="click" @command="handleClick">
          <span class="el-dropdown-link">
            <TIButton icon="import_blue" :label="$t('Імпорт')" dropdown="true" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="import-sku-from-xlsx"
              ><img class="svg" :src="require('../../assets/icons/import_blue.svg')" />{{ $t("із Excel (.xlsx)") }}</el-dropdown-item
            >
            <el-dropdown-item command="import-sku-from-json"
              ><img class="svg" :src="require('../../assets/icons/import_blue.svg')" />{{ $t("із JSON") }}</el-dropdown-item
            >
            <el-dropdown-item command="import-sku-from-gm"
              ><img class="svg" :src="require('../../assets/icons/import_blue.svg')" />{{ $t("із конкурентних груп") }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown trigger="click" @command="handleClick">
          <span class="el-dropdown-link">
            <TIButton icon="export_blue" :label="$t('Експорт')" dropdown="true" :disabled="!selectedRows2" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="export-sku-to-json" :disabled="!selectedRows2"
              ><img class="svg" :src="require('../../assets/icons/export_blue.svg')" />{{ $t("в JSON") }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <TSeparator />
      </el-row>
    </el-header>
    <el-container v-loading="loading" :element-loading-text="loadingText" style="padding-top:16px;">
      <div ref="flex_container" class="flex_container">
        <ag-grid-vue
          ref="findSKUTable2"
          id="findSKUTable2"
          style="width: 100%; height: 100%;"
          class="ag-theme-alpine"
          rowSelection="multiple"
          :defaultColDef="defaultColDef"
          :columnDefs="columns"
          :headerHeight="32"
          :rowData="selectedSKU"
          :gridOptions="gridOptions2"
          :frameworkComponents="frameworkComponents"
          @selection-changed="onGridSelectionChanged2"
          :overlayNoRowsTemplate="noRowsToShowTemplate"
          @sortChanged="onGridColumnsChange2"
          @columnResized="onGridColumnsChange2"
          @columnMoved="onGridColumnsChange2"
          @displayedColumnsChanged="onGridColumnsChange2"
          @gridReady="onGridReady2"
          :modules="modules"
          :localeTextFunc="gridLocale"
          :sideBar="sideBar"
          :enableBrowserTooltips="true"
          :detailCellRendererParams="detailCellRendererParams"
          :masterDetail="true"
        >
        </ag-grid-vue>
      </div>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button
        native-type="submit"
        type="success"
        @click="onCommit"
        class="apply-button"
        :disabled="target == 'restrict' && (!selectedRows2 || !selectedRows2.length)"
        >{{ $t("Застосувати") }}</el-button
      >
      <el-button type="text" @click="dialogShow = false" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
    <FindSKU @find-commit="skuImportData" bus-event="find.sku.dictionary" />
    <SKUInfo bus-event="dict.sku.forms.info" />
    <ImportSKUFromGM @find-commit="skuImportData" bus-event="find.sku.gm" />
    <SelectFile @commit="importSKUFromJSONData" bus-event="dict.sku.forms.select.file" />
    <SelectFileForImportSKUFromXLS @commit="skuImportData" bus-event="sku.forms.select.xls.file" />
  </el-dialog>
</template>

<script>
import { bus } from "@/main";
import { _ } from "vue-underscore";
import { AgGridVue } from "@ag-grid-community/vue";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import Icon from "@/components/IconPack";
import TIButton from "@/components/ToolbarIconButton";
import TSeparator from "@/components/ToolbarSeparator";
import GridCellWithIcons from "@/components/GridCellWithIcons.js";

function parseError(error) {
  if (typeof error == "string") {
    return error;
  } else if (Array.isArray(error)) {
    return error.join("; ");
  } else return error;
}

export default {
  name: "SKUDictionary",
  components: {
    AgGridVue,
    Splitpanes,
    Pane,
    Icon,
    TIButton,
    TSeparator,
    SelectFileForImportSKUFromXLS: () => import("@/components/forms/SelectFileForImportSKUFromXLS"),
    FindSKU: () => import("@/components/forms/FindSKU"),
    SKUInfo: () => import("@/components/forms/SKUInfo"),
    ImportSKUFromGM: () => import("@/components/forms/ImportSKUFromGM"),
    SelectFile: () => import("@/components/forms/SelectFile"),
  },
  data() {
    var that = this;
    return {
      modules: AllModules,
      dialogShow: false,
      dialogHeight: 0,
      resizeObserver: null,
      title: $tt("Пошук"),
      target: null,
      loading: false,
      loadingText: "",
      model: {},
      initModel: {},
      filterString: "",
      searchBy: "name",
      frameworkComponents: null,
      noRowsToShowTemplate: `<div><img src="${require("../../assets/icons/no_data.svg")}"></div>`,
      selectedRows2: null,
      selectedSKU: [],
      keyUpTimer: null,
      gridApi2: null,
      gridColumnApi2: null,
      gridOptions2: {
        suppressCellSelection: true,
        getRowNodeId: function(data) {
          return data.drugsId;
        },
        immutableData: true,
        rowHeight: 32,
        floatingFiltersHeight: 32,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
          ],
        },
      },
      sideBar: {
        toolPanels: ["columns", "filters"],
        position: "left",
        hiddenByDefault: false,
      },
      defaultColDef: {
        suppressNavigable: true,
        cellClass: "no-border",
        resizable: true,
        sortable: true,
        tooltipValueGetter: function(params) {
          return params.value;
        },
        floatingFilter: this.$store.getters["main/userSettings"]("userSettings").floatingFilter == "true",
        suppressMenu: false,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: { suppressFilterButton: true },
      },

      columns: [
        {
          headerName: $tt("Назва"),
          field: "sku",
          flex: 2,
          headerCheckboxSelection: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          //cellRenderer: "GridCellWithIcons",
          cellRenderer: "agGroupCellRenderer",
          cellIcons: [
            {
              icon: "edit",
              iconClass: "icon-action",
              type: "fas el-icon-fa-",
              color: "green",
              title: $tt("Редагувати"),
              onClick: (params) => {
                this.editSelected(null, params.data);
              },
            },
            {
              icon: "trash",
              iconClass: "icon-action",
              type: "fas el-icon-fa-",
              color: "red",
              title: $tt("Видалити"),
              onClick: (params) => {
                this.onDel(null, params);
              },
            },
            {
              icon: "info-circle",
              type: "fas el-icon-fa-",
              color: "blue",
              iconClass: "icon-action",
              title: $tt("Інформація"),
              onClick: (params) => {
                this.showInfo(null, params.data);
              },
            },
          ],
        },
        {
          headerName: "Morion ID",
          field: "drugsId",
          width: "200px",
          type: "rightAligned",
        },
        {
          headerName: $tt("Виробник"),
          field: "maker",
          flex: 1,
        },
        {
          headerName: $tt("Маркетуюча організація"),
          field: "marketOrg",
          flex: 1,
        },
        {
          headerName: "Бренд",
          field: "brand",
          flex: 1,
        },
        {
          headerName: $tt("Рег. дата початок"),
          field: "regDateBegin",
          width: "200px",
        },
        {
          headerName: $tt("Рег. дата завершення"),
          field: "regDateEnd",
          width: "200px",
        },
        {
          headerName: $tt("Форма"),
          field: "drugForm",
          flex: 1,
        },
        {
          headerName: $tt("Номер"),
          field: "drugFormNumber",
          width: "100px",
          type: "rightAligned",
        },
        {
          headerName: $tt("CIP ціна"),
          field: "cip",
          width: "100px",
          type: "rightAligned",
        },
        {
          headerName: $tt("Статус"),
          field: "stat",
          width: "100px",
        },
        {
          headerName: $tt("Статус2"),
          field: "stat2",
          width: "100px",
        },
      ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
      detailCellRendererParams: {
        detailGridOptions: {
          suppressCellSelection: true,
          getRowNodeId: function(data) {
            return data._id;
          },
          immutableData: true,
          rowHeight: 32,
          headerHeight: 32,
          floatingFiltersHeight: 32,
          defaultColDef: {
            suppressNavigable: true,
            cellClass: "no-border",
            resizable: true,
            sortable: true,
            width: 150,
            tooltipValueGetter: function(params) {
              return params.value;
            },
            floatingFilter: this.$store.getters["main/userSettings"]("userSettings").floatingFilter == "true",
            suppressMenu: false,
            filter: "agTextColumnFilter",
            floatingFilterComponentParams: { suppressFilterButton: true },
          },
          columnDefs: [
            { field: "contractName", headerName: $tt("Назва договору"), flex: 1 },
            { field: "restrictName", headerName: $tt("Назва умови"), flex: 1 },
            {
              field: "restrictType",
              headerName: $tt("Тип умови"),
              valueGetter: (params) => {
                return $tt(that.selectOptions.restricts.type.find((option) => option.value == params.data.restrictType).label);
              },
            },
            {
              field: "restrictPlanMeasure",
              headerName: $tt("План, одиниці"),
              valueGetter: (params) => {
                return $tt(that.selectOptions.restricts.planMeasure.find((option) => option.value == params.data.restrictPlanMeasure).label);
              },
            },
            {
              field: "restrictBonusMeasure",
              headerName: $tt("Бонус, одиниці"),
              valueGetter: (params) => {
                return $tt(that.selectOptions.restricts.bonusMeasure.find((option) => option.value == params.data.restrictBonusMeasure).label);
              },
            },
            { field: "restrictRulePlan", headerName: $tt("План"), type: "rightAligned", width: 100 },
            { field: "restrictRuleBonus", headerName: $tt("Бонус"), type: "rightAligned", width: 100 },
          ],
        },
        // get the rows for each Detail Grid
        getDetailRowData: async (params) => {
          try {
            var data = await this.$store.dispatch("sku/get_restrict_list_by_sku", params.data.drugsId);
            params.successCallback(data.data);
          } catch (err) {
            globalErrorMessage($tt("Помилка"), err);
          }
        },
      },
    };
  },
  mounted() {
    this.lang = this.$store.getters["main/userSettings"]("findSKULang") || "ru";
    this.searchBy = this.$store.getters["main/userSettings"]("findSKUsearchBy") || "name";
  },

  created() {
    bus.$on("forms.sku.dictionary.show", (data) => {
      if (!this.dialogShow) {
        this.title = (data && data.title) || this.title;
        this.initModel = _.extend({}, data ? data.initModel : {});
        this.target = data.target;
        this.filterString = "";
        this.selectedSKU = data && data.selectedSKU ? data.selectedSKU : [];
        this.dialogShow = true;
      }
    });
    bus.$on("forms.sku.dictionary.hide", () => {
      this.dialogShow = false;
      this.resizeObserver = null;
    });
  },
  beforeMount() {
    this.frameworkComponents = { GridCellWithIcons };
  },
  computed: {
    selectOptions() {
      return this.$store.getters["main/selectionOptions"];
    },
  },
  watch: {
    dialogHeight(value) {
      var containerHeight = value - 48 - 72 - 64;
      this.$refs.flex_container.style.height = `${containerHeight}px`;
      this.$refs.flex_container.style.width = `100%`;
    },
  },
  methods: {
    onGridReady2() {
      this.gridApi2 = this.gridOptions2.api;
      this.gridColumnApi2 = this.gridOptions2.columnApi;
      var state = this.$store.getters["main/userSettings"]("SKUDictionaryGridColumnState2");
      if (state) this.gridColumnApi2.setColumnState(state);
      this.checkGridSettingsToChanges(this, $tt("Товари"), "SKUDictionaryGridColumnState2", this.columns);
    },
    observeHeight() {
      var that = this;
      if (!this.resizeObserver) {
        this.resizeObserver = new ResizeObserver(function() {
          if (that.$refs.dialogWrapper && that.$refs.dialogWrapper.$el) that.dialogHeight = that.$refs.dialogWrapper.$el.children[0].clientHeight;
        });
        this.resizeObserver.observe(this.$el.children[0]);
      }
    },
    unobserveHeight() {
      this.resizeObserver && this.resizeObserver.unobserve(this.$el.children[0]);
      this.resizeObserver = null;
      this.dialogHeight = 0;
    },
    onOpen() {
      this.observeHeight();
    },
    onOpened() {
      //this.loading = true;
    },
    onClose() {
      this.dialogShow = false;
    },
    onClosed() {
      this.unobserveHeight();
    },
    onCommit() {
      if (this.target == "restrict") {
        if (this.selectedRows2 && this.selectedRows2.length) {
          this.$emit("find-commit", { target: this.target, result: this.selectedRows2 });
          this.dialogShow = false;
        } else this.$message.error($tt("Будь ласка, оберіть один або декілька рядків в таблиці!"));
      } else {
        this.$emit("find-commit", { target: this.target, result: this.selectedSKU });
        this.dialogShow = false;
      }
    },
    onAdd() {
      bus.$emit("find.sku.dictionary.show", {
        title: $tt("Пошук товарів"),
        operation: "contract.sku.find",
      });
    },
    onDel(event, params) {
      if (params) {
        this.selectedSKU = this.selectedSKU.filter((item) => item.drugsId !== params.data.drugsId);
      } else if (this.selectedRows2 && this.selectedRows2.length > 0) {
        var delIds = this.selectedRows2.map((item) => item.drugsId);
        this.selectedSKU = this.selectedSKU.filter((item) => delIds.indexOf(item.drugsId) == -1);
      }
    },
    onGridSelectionChanged2() {
      var rows = this.gridOptions2.api.getSelectedRows();
      if (rows && rows.length > 0) {
        this.selectedRows2 = rows;
      } else {
        this.selectedRows2 = null;
      }
    },
    onGridColumnsChange2() {
      var that = this;
      if (this.changeColumnsTimer) clearTimeout(this.changeColumnsTimer);
      this.changeColumnsTimer = setTimeout(() => {
        var state = that.gridColumnApi2.getColumnState();
        clearTimeout(that.changeColumnsTimer);
        that.$store.dispatch("main/updateUserSettings", { SKUDictionaryGridColumnState2: state });
      }, 500);
    },
    gridLocale(key, defaultValue) {
      var localized = $tt("grid." + key);
      return localized ? localized : defaultValue;
    },
    importSKUFromXLS() {
      bus.$emit("sku.forms.select.xls.file.show", {
        title: $tt("Завантаження списку товарів"),
        operation: "contract.sku.import.xls",
        initModel: { skuExists: this.selectedSKU },
      });
    },
    importSKUFromJSON() {
      bus.$emit("dict.sku.forms.select.file.show", {
        title: $tt("Імпорт товарів"),
        operation: "sku.import",
        initModel: {},
      });
    },
    importSKUFromJSONData(data) {
      var that = this;
      bus.$emit("dict.sku.forms.select.file.clear");
      that
        .readJSONFile(data.file)
        .then((result) => {
          if (result && result.dataType && result.dataType == "skuArray" && result.data) {
            that.skuImportData({
              operation: "contract.sku.import.json",
              result: result.data.filter((item) => item.dataType == "sku").map((item) => item.data),
            });
          } else if (result && result.dataType && result.dataType == "sku" && result.data) {
            that.skuImportData({ operation: "contract.sku.import.json", result: [result.data] });
          } else {
            globalErrorMessage($tt("Помилка імпорту"), $tt("Помилка в структурі JSON файлу"));
          }
        })
        .catch((err) => {
          globalErrorMessage($tt("Помилка імпорту"), err);
        });
    },
    importSKUFromGM() {
      bus.$emit("find.sku.gm.show", {
        title: $tt("Імпорт із конкурентних груп"),
        operation: "contract.sku.import.gm",
        initModel: { skuExists: this.selectedSKU },
      });
    },
    exportSKUToJSON() {
      if (this.selectedRows2) {
        var dt = new Date()
          .toLocaleString()
          .split(",")
          .join("-");
        if (this.selectedRows2.length > 1) {
          var result = this.selectedRows2.map((sku) => {
            return { dataType: "sku", data: sku };
          });
          this.saveDataToJSON({ dataType: "skuArray", data: result }, "skuArray_" + dt + ".json");
        } else {
          this.saveDataToJSON({ dataType: "sku", data: this.selectedRows2[0] }, "sku_" + this.selectedRows2[0].drugsId + "_" + dt + ".json");
        }
      }
    },
    skuImportData(data) {
      var that = this;
      if (data.operation == "contract.sku.import.xls") {
        if (data && data.data.length) {
          var existIds = this.selectedSKU.map((item) => item.drugsId);
          var newSKU = data.data.filter((item) => existIds.indexOf(item.drugsId) == -1);
          this.selectedSKU = this.selectedSKU.concat(newSKU);
        }
      } else if (data.operation == "contract.sku.find") {
        if (data && data.result && data.result.length) {
          var existIds = this.selectedSKU.map((item) => item.drugsId);
          var newSKU = data.result.filter((item) => existIds.indexOf(item.drugsId) == -1);
          this.selectedSKU = this.selectedSKU.concat(newSKU);
        }
      } else if (data.operation == "contract.sku.import.gm") {
        if (data && data.result && data.result.length) {
          var existIds = this.selectedSKU.map((item) => item.drugsId);
          var newSKU = data.result.filter((item) => existIds.indexOf(item.drugsId) == -1);
          this.selectedSKU = this.selectedSKU.concat(newSKU);
        }
      } else if (data.operation == "contract.sku.import.json") {
        if (data && data.result && data.result.length) {
          var existIds = this.selectedSKU.map((item) => item.drugsId);
          var newSKU = data.result.filter((item) => existIds.indexOf(item.drugsId) == -1);
          this.selectedSKU = this.selectedSKU.concat(newSKU);
        }
      }
    },
    onUpdateinfo() {},
    handleClick(command) {
      switch (command) {
        case "import-sku-from-xlsx": {
          this.importSKUFromXLS();
          break;
        }
        case "import-sku-from-json": {
          this.importSKUFromJSON();
          break;
        }
        case "import-sku-from-gm": {
          this.importSKUFromGM();
          break;
        }
        case "export-sku-to-json": {
          this.exportSKUToJSON();
          break;
        }
        default:
          break;
      }
    },
    showInfo(event, data) {
      if (data) {
        bus.$emit("dict.sku.forms.info.show", {
          title: $tt("Інформація про товар"),
          initModel: { drugsId: data.drugsId },
        });
      } else if (this.selectedRows2 && this.selectedRows2.length) {
        bus.$emit("dict.sku.forms.info.show", {
          title: $tt("Інформація про товар"),
          initModel: { drugsId: this.selectedRows2[0].drugsId },
        });
      }
    },
  },
};
</script>

<style lang="scss" scope>
#SKUDictionaryDialog .header2 {
  background: #fff;
  line-height: 32px;
  border-bottom: 1px solid #ccc !important;
  padding: 0 !important;
  align-items: center;
  margin-left: -16px;
  margin-right: -16px;
}

#SKUDictionaryDialog.my-dialog .el-dialog--center .el-dialog__body {
  padding: 0px 16px 0 16px !important;
}
</style>
